<template>
  <div>
    <template v-for="item in reactiveParams.menuList" :key="item.id">
      <van-cell :title="item.menuName" is-link @click="menuClick(item)" />
      <!-- <div @click="more(item)">
        {{ item.menuName }}
      </div> -->
    </template>
  </div>
</template>
<script>
  import {
    ref,
    getCurrentInstance,
    onMounted,
    onUpdated,
    provide,
    reactive,
    watch,
    toRefs,
  } from "vue";
  import { useRoute, useRouter } from "vue-router";
  export default {
    setup() {
      const router = useRouter();
      const routers = useRoute();
      // v3路由跳转
      // router.push('/household/usercenter')
      const reactiveParams = reactive({
        menuList: [],
        MenuId: routers.query.tokens,
      });
      // 初始化数据
      const initialize = () => {
        // 获取id菜单列表
        let MenuArray = JSON.parse(localStorage.getItem("Mens"));
        console.log(MenuArray);
        let soleMenu = MenuArray.filter((item) => {
          return item.id == reactiveParams.MenuId;
        });
        reactiveParams.menuList = soleMenu[0].children;
        console.log(reactiveParams.menuList);
      };
      const menuClick = (e) => {
        console.log(e);
        router.push({ name: e.menuCode });
      };
      onMounted(() => {
        initialize();
      });
      return {
        ...toRefs(reactiveParams),
        reactiveParams,
        initialize,
        onMounted,
        menuClick,
      };
    },
  };
</script>
<style lang="scss" scoped></style>
